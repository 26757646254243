import React, { useState } from "react";
import { AccountInfo } from "@azure/msal-browser";
import TimesheetTableView from "./components/TimesheetTableView/TimesheetTableView";
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimesheetEntry from "./components/TimesheetEntry/TimesheetEntry";
import NavMenu from "./components/NavMenu/NavMenu";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import LoginModal from "./components/LoginModal/LoginModal";
import Sandbox from "./components/Sandbox/Sandbox";

function App() {
  // current authenticated user
  const [currentUser, setCurrentUser] = useState<AccountInfo>();

  // authentication callback
  const onAuthenticated = async (userAccountInfo: AccountInfo) => {
    setCurrentUser(userAccountInfo);
  };

  // Render JSON data in readable format
  const PrettyPrintJson = ({ data }: any) => {
    return (
      <div>
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </div>
    );
  };

  return (
    <div id="App">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Router>
          <NavMenu user={currentUser}/>
          <LoginModal onAuthenticated={onAuthenticated} currentUser={currentUser} />

          <Switch>
            <Route path="/timesheet-approve">
              <TimesheetTableView/>
            </Route>
            <Route path="/timesheet-entry">
              <TimesheetEntry/>
            </Route>
            <Route exact path="/">
              <PrettyPrintJson data={currentUser}/>
            </Route>
            <Route path="/sandbox">
              <Sandbox/>
            </Route>
          </Switch>
        </Router>
      </LocalizationProvider>
    </div>
  );
}

export default App;