import * as React from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { InputAdornment, MenuItem } from '@mui/material';
import EmployeeDropdown from '../EmployeeDropdown/EmployeeDropdown';

export default function TimesheetItemApprove() {
    const [open, setOpen] = React.useState(false);
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  
    return (
      <div>
        <EmployeeDropdown />

        <EmployeeDropdown maint={true}/>

        <EmployeeDropdown maint={false}/>

        <EmployeeDropdown hideSelf={true}/>
      </div>
    );
  }