import { Card, CardContent, Typography } from "@mui/material"
import React from "react";


interface Props {
    workOrder: any
};

export default function WorkOrderCard(props:Props) {
    return (
        <Card variant="outlined" sx={{flexGrow: 1}}>
            <CardContent>
                <Typography sx={{ fontSize: 10 }} color="text.secondary">
                    Work Order: {props.workOrder.xnNumber}
                </Typography>
            </CardContent>
            <CardContent>Property: {props.workOrder.propertyID}</CardContent>
            <CardContent>Type: {props.workOrder.type}</CardContent>
            <CardContent>Description: {props.workOrder.description}</CardContent>
            
            
        </Card>
        
    );
};