import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';



export default function WorkTypeDropdown(props:any) {
  const { id, value, api, field } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    api.setEditCellValue({ id, field, value: Number(event.target.value) }, event);
  };

  const types = [
      {label: "Normal", value: 0},
      {label: "Supplies", value: 1},
      {label: "Office", value: 3},
      {label: "Overtime", value: 4},
      {label: "2xOT", value: 5},
      {label: "Personal", value: -4},
      {label: "Holiday", value: -3},
      {label: "Sick", value: -2},
      {label: "Vacation", value: -1},
  ];

  return (
      <div>
        <TextField
          select
          value={value}
          onChange={handleChange}
          size="small"
          fullWidth
        >
          {types.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
  );
}
