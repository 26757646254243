import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { createFilterOptions } from '@mui/material/Autocomplete';

interface GLAccount {
    assocCode: string
    glAccountID: number,
    code: string,
    description: string,
    lastModified: Date,
    inActive: Date
}

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (gl:GLAccount) => `${gl.code} ${gl.description}`,
});

export default function GLAutocomplete(params:any) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly GLAccount[]>([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await fetch(process.env.REACT_APP_API_BASEURL + "/GetVantacaGL");
      const data = await response.json();

      if (active) {
        setOptions(data);
      }
    })();


    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      {...params}
      sx={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option:GLAccount, value: any) => option.glAccountID === value.glAccountID}
      getOptionLabel={(option:GLAccount) => option.code + ": " + option.description}
      options={options}
      loading={loading}
      size="small"
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
