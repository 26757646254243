import * as React from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Card, CardContent, FormGroup, InputAdornment, MenuItem, Stack, styled, Typography } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import TimesheetItem from '../../models/TimesheetItem';
import WorkOrderCard from '../WorkOrderCard/WorkOrderCard';
import GLAutocomplete from '../GLAutocomplete/GLAutocomplete';

interface Props {
  item: TimesheetItem | undefined
  onClose: () => any
};

interface State {
  markupPercent: number,
  approved_materials_charge: number,
  approved_labor_charge: number,
  glAccountId?: number,
  labor_rate: number,
  labor_rate_modifier: number,
  approved_miles_charge: number,
  miles_rate: number,
  description: string,
  hours: number,
  miles: number,
  material_cost: number,
  workOrder?: any|undefined
};

const CssTextField = styled(TextField)({
  backgroundColor: "#f0f0ff"
});

export default class TimesheetItemApprove extends React.Component<Props, State> {
  defaultMilesRate = 1;
  defaultMarkupPercent = 30;
  defaultLaborRateModifier = 1;

  constructor(props: Props) {
    super(props);


    this.state = {
      markupPercent: this.defaultMarkupPercent,
      approved_labor_charge: (props.item?.hours || 0) * this.defaultLaborRateModifier,
      approved_miles_charge: (props.item?.miles || 0) * this.defaultMilesRate,
      approved_materials_charge: (props.item?.material_cost || 0) * this.defaultMarkupPercent,
      miles_rate: 1,
      description: props.item?.description || "",
      labor_rate: 100,
      labor_rate_modifier: this.defaultLaborRateModifier,
      hours: props.item?.hours || 0,
      miles: props.item?.miles || 0,
      material_cost: props.item?.material_cost || 0
    };
    
    this.approveTime = this.approveTime.bind(this);
  }

  recalculateLabor() {
    this.setState({approved_labor_charge: this.state.labor_rate * this.state.labor_rate_modifier * this.state.hours});
  }

  recalculateMiles() {
    this.setState({approved_miles_charge: this.state.miles_rate * this.state.miles});
  }

  recalculateMaterials() {
    this.setState({approved_materials_charge: (1 + this.state.markupPercent/100) * this.state.material_cost});
  }

  async approveTime() {
    debugger;
    let ts = {...this.props.item, ...this.state};
    let response = await fetch(process.env.REACT_APP_API_BASEURL + "/ApproveTimesheetItem", {
      method: "POST",
      body: JSON.stringify({timesheet:ts, wo: null})
    });

    this.props.onClose();
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_API_BASEURL + "/GetVantacaWorkOrders?workOrderId=" + 10000)
    .then((res) => res.json())
    .then((json) => {
        this.setState({
            workOrder: json[0]
        });
      });

    this.setState({
      hours: this.props.item?.hours || 0,
      miles: this.props.item?.miles || 0,
      material_cost: this.props.item?.material_cost || 0
    });
  }

  render() {
    const props = this.props;

    return (
      <div>
        <Dialog open={this.props.item !== undefined} onClose={this.props.onClose} maxWidth="lg" fullWidth={true}>
          <DialogTitle>Approve Time</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Edit timesheet data as needed. Shaded values entered here will be entered on an invoice and will be visible to owners. <br />
              Each timesheet approval can generate 3 invoice line items: labor, transportation, and materials.
            </DialogContentText>

            <Box sx={{ display: "flex" }}>
              <Card variant="outlined" sx={{ flexGrow: 2 }}>
                <CardContent>
                  <Typography sx={{ fontSize: 10 }} color="text.secondary">
                    Timesheet Item
                  </Typography>
                </CardContent>
                <CardContent>
                  <Stack direction="row" spacing={2}>

                  <TextField
                      margin="dense"
                      value={this.state.hours}
                      onChange={(e) => {
                        this.setState({ hours: Number.parseFloat(e.target.value) });
                        this.recalculateLabor();
                      }}
                      id="hours"
                      label="Hours"
                      variant="standard"
                      size="small"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{props.item && props.item.hours} <ArrowRightAltIcon /></InputAdornment>,
                      }}
                    />
                    <GLAutocomplete
                      margin="dense"
                      label="Category/GL"
                      variant="standard"
                      size="small"
                      value={this.state.glAccountId}
                      onChange={(e:any) => {
                        this.setState({ glAccountId: Number.parseFloat(e.target.value) });
                        this.recalculateLabor();
                      }}
                      InputLabelProps={{shrink:true}}
                    />
                    <TextField
                      margin="dense"
                      id="rate"
                      label="Rate Modifier"
                      value={this.state.labor_rate_modifier}
                      onChange={(e) => {
                        this.setState({ labor_rate_modifier: Number.parseFloat(e.target.value) });
                        this.recalculateLabor();
                      }}
                      sx={{ width: "150px" }}
                      variant="standard"
                      InputLabelProps={{ shrink: true }}
                      select >
                      <MenuItem selected={true} value={1}>Std</MenuItem>
                      <MenuItem value={1.5}>OT</MenuItem>
                      <MenuItem value={2}>Dbl</MenuItem>
                      <MenuItem value={0}>No Charge</MenuItem>
                    </TextField>
                    <CssTextField
                      margin="dense"
                      value={this.state.approved_labor_charge}
                      onChange={(e) => {
                        this.setState({ approved_labor_charge: Number.parseFloat(e.target.value) });
                      }}
                      id="approved_labor_charge"
                      label="Labor Charge"
                      variant="standard"
                      size="small"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                  </Stack>

                  <Stack direction="row" spacing={2}>

                    <TextField
                      margin="dense"
                      id="miles"
                      value={this.state.miles}
                      onChange={(e) => {
                        this.setState({ miles: Number.parseFloat(e.target.value) });
                        this.recalculateMiles();
                      }}
                      label="Miles"
                      variant="standard"
                      size="small"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{props.item && props.item.miles} <ArrowRightAltIcon /></InputAdornment>,
                      }}
                    />

                    <TextField
                      margin="dense"
                      id="miles_rate"
                      value={this.state.miles_rate}
                      onChange={(e) => {
                        this.setState({ miles_rate: Number.parseFloat(e.target.value) });
                        this.recalculateMiles();
                      }}
                      label="Mile Rate"
                      variant="standard"
                      size="small"
                    />

                    <CssTextField
                      margin="dense"
                      id="approved_miles_charge"
                      value={this.state.approved_miles_charge}
                      onChange={(e) => {
                        this.setState({approved_miles_charge: Number.parseFloat(e.target.value)});
                      }}
                      label="Transportation Charge"
                      variant="standard"
                      size="small"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                  </Stack>


                  <Stack direction="row" spacing={2}>




                    <TextField
                      margin="dense"
                      id="materials"
                      value={this.state.material_cost}
                      onChange={(e) => {
                        this.setState({ material_cost: Number.parseFloat(e.target.value) });
                        this.recalculateMaterials();
                      }}
                      label="Materials"
                      variant="standard"
                      size="small"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{props.item && props.item.material_cost} <ArrowRightAltIcon /></InputAdornment>
                      }}
                    />
                    <TextField
                      margin="dense"
                      id="markup"
                      value={this.state.markupPercent}
                      onChange={(e) => { 
                        this.setState({ markupPercent: Number.parseFloat(e.target.value) });
                        this.recalculateMaterials();
                      }}
                      label="Mat. Markup"
                      variant="standard"
                      size="small"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                      }}
                    />
                    <CssTextField
                      margin="dense"
                      id="approved_materials_charge"
                      value={this.state.approved_materials_charge}
                      onChange={(e) => {
                        this.setState({ approved_materials_charge: Number.parseFloat(e.target.value) });
                      }}
                      label="Material Charge"
                      variant="standard"
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                    />
                  </Stack>

                  <Stack direction="row" spacing={2}>


                    <TextField
                      margin="dense"
                      sx={{ width: "150px" }}
                      id="status"
                      label="Status"
                      variant="standard"
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      select
                    >
                      <MenuItem value="IP">In Progress</MenuItem>
                      <MenuItem value="WC">Work Completed</MenuItem>
                    </TextField>

                    <CssTextField
                      margin="dense"
                      id="approved_description"
                      value={this.state.description}
                      onChange={(e) => this.setState({description: e.target.value})}
                      label="Description"
                      type="text"
                      variant="standard"
                      multiline={true}
                      fullWidth={true}
                      InputLabelProps={{shrink:true}}
                    />

                  </Stack>
                </CardContent>
              </Card>

              <WorkOrderCard workOrder={this.state.workOrder} />

            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.onClose()}>Cancel</Button>
            <Button onClick={this.approveTime}>Approve</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  };
}
