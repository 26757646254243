import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason, AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

interface Employee {
  id: number;
  name: string;
}

interface EmployeeDropdownProps {
  current?:boolean|undefined;
  maint?:boolean|undefined;
  hideSelf?:boolean|undefined;
  AutocompleteProps?:AutocompleteRenderInputParams|undefined;
  onChange?: (
    event: React.SyntheticEvent<Element, Event>, 
    value: {
      id: number;
      label: string;
    } | null, 
    reason: AutocompleteChangeReason, 
    details?: AutocompleteChangeDetails<{id:number,label:string}> | undefined) => void | undefined;
};

interface EmployeeDropdownState {
  options: {id:number, label:string}[];
  loading: boolean;
  open: boolean
};

const EmployeeDropdownDefaults:EmployeeDropdownProps = {
  current: true,
  maint: undefined,
  hideSelf: false,
  AutocompleteProps:undefined,
  onChange:undefined
};

const EmployeeDropdownStateDefaults:EmployeeDropdownState = {
  options: new Array<{id:number, label:string}>(),
  loading: true,
  open: false
};

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

class EmployeeDropdown extends React.Component<EmployeeDropdownProps, EmployeeDropdownState> {
  static defaultProps = EmployeeDropdownDefaults;
  
  state = EmployeeDropdownStateDefaults;


  componentDidMount() {
    fetch(process.env.REACT_APP_API_BASEURL + "/GetEmployees")
        .then((res) => res.json())
        .then((json) => {
            this.setState({
                options: json.recordset.map((e:any) => {
                  return {
                    id: e.EmployeeID, 
                    label: e.FirstName + " " + e.LastName
                  }
                }),
                loading: false
            });
        })
  }

  render() {
    return (
      <Autocomplete
        sx={{ width: 300 }}
        open={this.state.open}
        onOpen={() => {
          this.setState({open: true});
        }}
        onClose={() => {
          this.setState({open: false});
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.label}
        options={this.state.options}
        loading={this.state.loading}
        onChange={this.props.onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    );
  }
}

export default EmployeeDropdown;

