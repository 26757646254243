import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { createFilterOptions } from '@mui/material/Autocomplete';

interface WorkOrder {
  xnNumber: number;
  property: string;
  description: string;
}

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (wo:WorkOrder) => wo.xnNumber.toString(),
});

export default function WorkOrderAutocomplete(params:any) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly WorkOrder[]>([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await fetch(process.env.REACT_APP_API_BASEURL + "/GetVantacaWorkOrders");
      const data = await response.json();

      if (active) {
        setOptions(data);
      }
    })();


    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      {...params}
      sx={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option:WorkOrder, value: any) => option.xnNumber === value.xnNumber}
      getOptionLabel={(option:WorkOrder) => option.xnNumber + " " + option.description}
      options={options}
      loading={loading}
      size="small"
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
