import * as React from 'react';
import moment, { Moment } from 'moment';
import { Backdrop, Box, Button, CircularProgress, TextField } from '@mui/material';
import { DatePicker } from '@mui/lab';
import { DataGrid, GridColDef, GridRowParams, GridSelectionModel, MuiEvent } from '@mui/x-data-grid';
import CheckIcon from '@mui/icons-material/Check';
import TimesheetItemApprove from '../TimesheetItemApprove/TimesheetItemApprove';
import TimesheetItem from '../../models/TimesheetItem';

const columns: GridColDef[] = [
  { 
    field: 'work_type', 
    headerName: 'Type',
    sortable: false,
    disableColumnMenu: true,
    valueGetter: (params) => {
      switch(params.row.work_type) {
        case 0: return "Normal";
        case 1: return "Supplies";
        case 3: return "Office";
        case 4: return "Overtime";
        case 5: return "2xOT";
        case -4: return "Personal";
        case -3: return "Holiday";
        case -2: return "Sick";
        case -1: return "Vacation:;"
      }
    }
  },
  {
    field: 'wo_id',
    headerName: 'WO',
    disableColumnMenu: true,
    sortable: false,
    
  },
  {
    field: 'association_id',
    headerName: 'Property',
    width: 75,
    disableColumnMenu: true,
    sortable: false
  },
  {
    field: 'unit',
    headerName: 'Unit',
    width: 60,
    disableColumnMenu: true,
    sortable: false
  },
  {
    field: 'time_in',
    headerName: 'In',
    sortable: false,
    width: 90,
    editable: true,
    disableColumnMenu: true,
    valueGetter: (params) => params.value? moment(params.value.toString()).format("h:mma") : ""
    
  },
  {
    field: 'time_out',
    headerName: 'Out',
    sortable: false,
    width: 90,
    disableColumnMenu: true,
    valueGetter: (params) => params.value? moment(params.value.toString()).format("h:mma") : ""
  },
  {
    field: 'hours',
    headerName: 'Hours',
    width: 70,
    disableColumnMenu: true,
    sortable: false
  },
  {
    field: 'miles',
    headerName: 'Miles',
    sortable: false,
    width: 70,
    disableColumnMenu: true
  },
  {
    field: 'material_cost',
    headerName: 'Materials',
    sortable: false,
    width: 100,
    disableColumnMenu: true,
  },
  {
    field: 'completed',
    headerName: 'Done?',
    sortable: false,
    width: 50,
    disableColumnMenu: true,
    renderCell: (params) => params.value?  <CheckIcon/> : ""
  },
  {
    field: 'coworker',
    headerName: 'Coworker',
    sortable: false,
    width: 100,
    disableColumnMenu: true,

  },
  {
    field: 'description',
    headerName: 'Description',
    sortable: false,
    flex: 1,
    disableColumnMenu: true,
    disableReorder: true,
  },
  {
    field: "loading",
    headerName: " ",
    disableColumnMenu: true,
    disableReorder: true,
    sortable: false,
    renderCell: (params:any) => params.value && <CircularProgress size="small" sx={{ height: '20px', width: '20px' }} />
  }
];

interface State {
  items: any[],
  loading: boolean,
  date: Moment,
  approvingItem: TimesheetItem | undefined
};


class TimesheetTableView extends React.Component<any, State> {
    constructor(props:any) {
      super(props);
      
      this.state = {
          items: [],
          loading: true,
          date: moment(),
          approvingItem: undefined
      };

      this.onRowDoubleClick = this.onRowDoubleClick.bind(this);
      this.onSelectionModelChange = this.onSelectionModelChange.bind(this);
      this.onCloseTimesheetApprove = this.onCloseTimesheetApprove.bind(this);
    }

    componentDidMount() {
      this.setDate(this.state.date);
    }

    setDate(newValue:Moment|null) {
      this.setState({loading: true, date: (newValue || moment())})

      fetch(process.env.REACT_APP_API_BASEURL + "/GetTimesheetData?date=" + this.state.date.format("YYYY-MM-DD"))
          .then((res) => res.json())
          .then((json) => {
              this.setState({
                  items: json.recordset,
                  loading: false
              });
          })
    }

    onSelectionModelChange(selectionModel: GridSelectionModel, details?: any) {

    }

    onRowDoubleClick(params: GridRowParams, event: MuiEvent<React.SyntheticEvent<Element, Event>>) {
      this.setState({approvingItem: params.row});
    }

    onCloseTimesheetApprove() {
      this.setState({approvingItem:undefined});
    }


    render() {
      return <div style={{height: '500px',  width: '100%' }}>
          <DatePicker
            value={this.state.date}
            onChange={(newValue) => {
              this.setDate(newValue)
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          

          <DataGrid
            rows={this.state.items}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[5, 10, 20]}
            checkboxSelection
            disableSelectionOnClick
            editMode="row"
            onSelectionModelChange={this.onSelectionModelChange}
            onRowDoubleClick={this.onRowDoubleClick}
          />

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme:any) => theme.zIndex.drawer + 1 }}
          open={this.state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <TimesheetItemApprove item={this.state.approvingItem} onClose={this.onCloseTimesheetApprove} />
      </div>;
    }
  };

export default TimesheetTableView;

