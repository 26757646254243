import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AzureAuthenticationButton from "../../azure/azure-authentication-component"
import { AccountInfo } from "@azure/msal-browser";

interface LoginModalProps {
    currentUser: AccountInfo|undefined
    onAuthenticated: any
}

export default function LoginModal(props: LoginModalProps) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={!props.currentUser}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Log in"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Log in using your Office 365/Azure Active Directory credentials. These may be different than the credentials used to log in to your computer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <AzureAuthenticationButton onAuthenticated={props.onAuthenticated} />
        </DialogActions>
      </Dialog>
    </div>
  );
}
