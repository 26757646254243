import * as React from 'react';
import TextField from '@mui/material/TextField';
import { MenuItem } from '@mui/material';
import moment from 'moment';
import { validateDateTime } from '@mui/lab/internal/pickers/date-time-utils';

export default function TimePicker(props:any) {
  const { id, value, api, field } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = moment("2021-11-20 " + event.target.value, "YYYY-MM-DD h:mma");
    api.setEditCellValue({ id, field, value: val }, event);
  };

  let times:string[] = [];
  for(let h = 0; h<24; h++) {
    for (let m = 0; m<60; m+=15) {
        let hh = h % 12;
        let ampm = (h <12? "am": "pm");
        let mm = (m == 0)? "00" : m;
        hh = hh == 0? 12 : hh;
        times.push(hh + ":" + mm + ampm);
    }
  }

  return (
    <TextField
      value={value? value.format("h:mma") : ""}
      onChange={handleChange}
      fullWidth
      select
    >
      {times.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
      </TextField>
  );
}
