import * as React from 'react';
import moment, { Moment } from 'moment';
import EmployeeDropdown from '../EmployeeDropdown/EmployeeDropdown';
import WorkOrderAutocomplete from '../WorkOrderAutocomplete/WorkOrderAutocomplete';
import WorkTypeDropdown from '../WorkTypeDropdown/WorkTypeDropdown';
import TimePicker from '../TimePicker/TimePicker';
import { DatePicker } from '@mui/lab';
import { Backdrop, Box, CircularProgress, FormControlLabel, FormGroup, Paper, Switch, TextField } from '@mui/material';
import { DataGrid, GridApiRef, GridRowData, GridRowParams, GridValueGetterParams, MuiEvent } from '@mui/x-data-grid'; 
import { GridColDef } from '@mui/x-data-grid';
import CheckIcon from '@mui/icons-material/Check';

const columns: GridColDef[] = [
  { 
    field: 'work_type', 
    headerName: 'Type', 
    editable: true,
    sortable: false,
    disableColumnMenu: true,
    renderEditCell: (params) => {
      return <WorkTypeDropdown {...params} />;
    },
    valueGetter: (params) => {
      switch(params.row.work_type) {
        case 0: return "Normal";
        case 1: return "Supplies";
        case 3: return "Office";
        case 4: return "Overtime";
        case 5: return "2xOT";
        case -4: return "Personal";
        case -3: return "Holiday";
        case -2: return "Sick";
        case -1: return "Vacation:;"
      }
    }
  },
  {
    field: 'wo_id',
    headerName: 'WO',
    editable: true,
    disableColumnMenu: true,
    sortable: false,
    renderEditCell: (params) => {
      return <WorkOrderAutocomplete {...params} />;
    }
    
  },
  {
    field: 'association_id',
    headerName: 'Property',
    width: 75,
    disableColumnMenu: true,
    sortable: false
  },
  {
    field: 'unit',
    headerName: 'Unit',
    width: 60,
    disableColumnMenu: true,
    sortable: false
  },
  {
    field: 'time_in',
    headerName: 'In',
    sortable: false,
    width: 90,
    editable: true,
    disableColumnMenu: true,
    valueGetter: (params) => {
      return params.value? moment(params.value.toString()).format("h:mma") : ""
    },
    renderEditCell: (params) =>{
      return <TimePicker {...params}/>;
    }
  },
  {
    field: 'time_out',
    headerName: 'Out',
    sortable: false,
    width: 90,
    editable: true,
    disableColumnMenu: true,
    valueGetter: (params) => {
      return params.value? moment(params.value.toString()).format("h:mma") : ""
    },
    renderEditCell: (params) =>{
      return <TimePicker {...params}/>;
    }
  },
  {
    field: 'hours',
    headerName: 'Hours',
    width: 70,
    disableColumnMenu: true,
    editable: false,
    sortable: false,
    valueGetter: (params:GridValueGetterParams) => {
      let end = params.row.time_out;
      let start = params.row.time_in;

      if (typeof(start)==="string") start = moment(start);
      if (typeof(end)==="string") end = moment(end);

      return (end !== undefined && start !== undefined)? end.diff(start, "hour", true) : "";
    }
  },
  {
    field: 'miles',
    headerName: 'Miles',
    sortable: false,
    width: 70,
    disableColumnMenu: true,
    editable: true
  },
  {
    field: 'material_cost',
    headerName: 'Materials',
    sortable: false,
    width: 100,
    disableColumnMenu: true,
    editable: true
  },
  {
    field: 'completed',
    headerName: 'Done?',
    sortable: false,
    width: 50,
    editable: true,
    disableColumnMenu: true,
    type: "boolean",
    renderCell: (params) => {
      return params.value?  <CheckIcon/> : ""
    },
  },
  {
    field: 'coworker',
    headerName: 'Coworker',
    sortable: false,
    width: 100,
    editable: true,
    disableColumnMenu: true,
    renderEditCell: (params) => {
      return <EmployeeDropdown {...params}/>;
    }

  },
  {
    field: 'description',
    headerName: 'Description',
    sortable: false,
    flex: 1,
    editable: true,
    disableColumnMenu: true,
    disableReorder: true,
  },
  {
    field: "loading",
    headerName: " ",
    disableColumnMenu: true,
    disableReorder: true,
    sortable: false,
    renderCell: (params:any) => {
      return (  
        params.value && <CircularProgress size="small" sx={{ height: '20px', width: '20px' }} />
      );
    }
  }
];

interface TimesheetEntryState {
  items: any[],
  loading: boolean,
  date: Moment,
  editable: boolean,
  employeeId: number | undefined,
  apiRef: GridApiRef | undefined
};


export default class TimesheetEntry extends React.Component<any, TimesheetEntryState> {
  constructor(props:any) {
    super(props);

    this.state = {
        items: [],
        loading: true,
        date: moment(),
        editable: true,
        employeeId: undefined,
        apiRef: undefined
    };

    this.setDayComplete = this.setDayComplete.bind(this);
    this.onEmployeeChange = this.onEmployeeChange.bind(this);
    this.onRowEditStop = this.onRowEditStop.bind(this);
  }

  componentDidMount() {
    this.setDate(this.state.date);
  }

  setDate(newValue:Moment|null) {
    this.setState({loading: true, date: (newValue || moment())})
    this.reloadData();
  }

  async setDayComplete(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    if (!checked) {
      return;
    }

    if (!window.confirm("Are you sure you want to mark this day complete? You will not be able to make any further changes.")) {
      event.preventDefault();
      return;
    }
    
    this.setState({editable: false, loading: true});
    await fetch(process.env.REACT_APP_API_BASEURL + "/SaveTimesheetDay?date=" + this.state.date.format("YYYY-MM-DD") + "&employeeID=" + this.state.employeeId)
    this.setState({
        editable: false,
        loading: false
    });
  }

  onEmployeeChange(event:any, value:any) {
    this.setState({employeeId: value && value.id});
    this.reloadData();
  }

  reloadData() {
    fetch(process.env.REACT_APP_API_BASEURL + "/GetTimesheetData?date=" + this.state.date.format("YYYY-MM-DD") + "&employeeID=" + this.state.employeeId)
        .then((res) => res.json())
        .then((json) => {
            let items = [ ...(json.recordsets[0])];
            for (let i=1; i<=10; i++)
              items.push({id: -1*i, miles: undefined});

            this.setState({
                items: items,
                loading: false,
                editable: json.recordsets[1].length === 0
            });

            //if (this.state.editable)
            //  this.state.items.forEach((i) => apiRef.current.setRowMode(i.id, 'edit'));
        })
  };

  async onRowEditStop(params: GridRowParams, event: MuiEvent<React.SyntheticEvent<Element, Event>>) {
    //event.defaultMuiPrevented = true;
    debugger;
    params.row.loading = true;
    await this.saveTimesheetItem(params.row);
  }

  // onRowEditCommit(id: GridRowId, event: MuiEvent<React.SyntheticEvent<Element, Event>>) {
  //   alert("onRowEditCommit");
  // }

  async saveTimesheetItem(data: GridRowData) {
    let post = {...data};
    post.time_in = post.time_in.format("YYYY-MM-DD h:mma");
    post.time_out = post.time_out.format("YYYY-MM-DD h:mma");

    await fetch(process.env.REACT_APP_API_BASEURL + "/SaveTimesheetItem", {
      method: "POST",
      body: JSON.stringify(post),
      headers: {
          "Content-type": "application/json; charset=UTF-8"
      }
    });
    data.loading = false;
    
    //process.env.REACT_APP_API_BASEURL + "/GetTimesheetData?date=" + this.state.date.format("YYYY-MM-DD") + "&employeeID=" + this.state.employeeId)
  }
  

  render() {
    return (
      <div style={{height: '750px',  width: '100%' }}>
      <Paper>
      <Box sx={{ display: 'flex' }}>
          <DatePicker
            value={this.state.date}
            onChange={(newValue) => {
              this.setDate(newValue)
            }}
            renderInput={(params) => <TextField {...params} />}
          />

          <Box sx={{ flexGrow: 1 }} />

          <EmployeeDropdown maint={true} onChange={this.onEmployeeChange} />

          <Box sx={{ flexGrow: 1 }} />

          <FormGroup>
            <FormControlLabel 
              control={<Switch onChange={this.setDayComplete} disabled={!this.state.editable} />} 
              label="Complete Day" />
          </FormGroup>

          </Box>
        </Paper>

        <DataGrid
          rows={this.state.items}
          columns={columns}
          rowHeight={30}
          pageSize={20}
          rowsPerPageOptions={[5, 10, 20]}
          checkboxSelection
          disableSelectionOnClick
          editMode='row'
          onRowEditStop={this.onRowEditStop}
          //onRowEditCommit={this.onRowEditCommit}
        />

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme:any) => theme.zIndex.drawer + 1 }}
          open={this.state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }
}